// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'chosen-js/chosen.css'
import 'chosen-js'

require("channels")
require("jquery")
require("packs/lightbox")
//require("packs/jquery.AddIncSearch.min")
require("packs/jquery.toChecklist")
require("packs/jquery.cycle.all.min")
//require("packs/check_box_selector")
//require("packs/table_sort")
Rails.start()
Turbolinks.start()
ActiveStorage.start()

console.log('application.js loaded')